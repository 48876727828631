/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface FormState {
  cardNumber: string
  expiredAt: string
  cvv: string
  fullName: string
  isCardInvalid: {
    number: boolean
    date: boolean
    holderName: boolean
  }
}

const initialState: FormState = {
  cardNumber: '',
  expiredAt: '',
  cvv: '',
  fullName: '',
  isCardInvalid: {
    number: false,
    date: false,
    holderName: false,
  },
}

export const orderSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setCardNumber: (state, action: PayloadAction<FormState['cardNumber']>) => {
      state.cardNumber = action.payload
    },
    setExpiredAt: (state, action: PayloadAction<FormState['expiredAt']>) => {
      state.expiredAt = action.payload
    },
    setCVV: (state, action: PayloadAction<FormState['cvv']>) => {
      state.cvv = action.payload
    },
    setFullName: (state, action: PayloadAction<FormState['fullName']>) => {
      state.fullName = action.payload
    },
    setIsCardNumberInvalid: (
      state,
      { payload }: PayloadAction<FormState['isCardInvalid']['number']>
    ) => {
      state.isCardInvalid.number = payload
    },
    setIsCardDateInvalid: (
      state,
      { payload }: PayloadAction<FormState['isCardInvalid']['date']>
    ) => {
      state.isCardInvalid.date = payload
    },
    setIsCardHolderNameInvalid: (
      state,
      { payload }: PayloadAction<FormState['isCardInvalid']['holderName']>
    ) => {
      state.isCardInvalid.holderName = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setCardNumber,
  setCVV,
  setExpiredAt,
  setFullName,
  setIsCardNumberInvalid,
  setIsCardDateInvalid,
  setIsCardHolderNameInvalid,
} = orderSlice.actions

export default orderSlice.reducer
